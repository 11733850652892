import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';


import img1 from '../assets/images/gd1.png';

function AppAbout() {


  return (
    <section id="about" className="block about-block">
      <Container fluid>
        <div className="title-holder">
          <h2>Tentang Kami</h2>
        </div>
        <Row>
          <Col sm={6}>
            <Image src={img1} />
          </Col>
          <Col sm={6}>
            <p>Gajian Duluan  adalah Branding dari Koperasi Konsumen Insan Swakarya Sejahtera atau biasa disebut Koperasi KISS. Dimana Koperasi ini berdiri pada tanggal 12 Maret 2021, berlokasi di Office Park 18 Lantai 17 Jl TB Simatupang no 18 Pasar Minggu Jakarta Selatan.</p>
            <p>Koperasi KISS adalah Koperasi Karyawan PT SIM dimana anggotanya adalah seluruh karyawan dari PT SIMGROUP. Sampai saat ini Koperasi KISS sudah melayani lebih dari ribuan karyawan dan terus bertumbuh dengan membantu lebih banyak lagi anggota Koperasi untuk mencapai tujuan kesejahteraan Bersama.</p>
            <div className='explore'>
            <a className="btn btn-secondary" href='https://gajianduluan.id/login'>Explore Now <i className="fas fa-chevron-right"></i></a>
            </div>
          </Col>
          <div className='visi' id='visi'>
          <h4>VISI KAMI</h4>  
          <li>menjadikan Koperasi yang mampu bersaing dalam usaha, guna mencapai kesejahteraan anggota</li>
      </div>
      <div  className='misi'>
      <h4>MISI KAMI</h4>
  <li>Meningkatkan profesionalisme pengelolaan Koperasi (Pengurus, Pengawas dan Anggota)</li>
  <li>Meningkatkan mutu manajemen dan tata Kelola yang transparan </li>
  <li>Meningkatkan partisipasi aktif anggota sebagai pemilik Koperasi</li>
  <li>Mengoptimalkan sumber daya yang ada untuk meningkatkan pelayanan dan usaha Koperasi</li>
  <li>Melakukan Kerjasama usaha yang saling menguntungkan dalam rangka pengembangan Koperasi</li>
  <li>Mengembangkan bidang usaha melalui pembentukan unit Usaha dalam rangka mewujudkan Visi Koperasi</li>
  </div>
        </Row>
      </Container>
    </section>
  );
}

export default AppAbout;