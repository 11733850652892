/* eslint-disable jsx-a11y/anchor-has-content */
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/images/gd1.png'


function AppHeader() {
  return (
    <Navbar bg="light" expand="lg">
      <Container>
      
        <Navbar.Brand href="#home" ><img src={Logo} alt='logo' href='#home'/>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto"> 
            <Nav.Link href="#home">Beranda</Nav.Link>
            <Nav.Link href="#about">Tentang Kami</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link> 
            <button className="btn btn-primary"><a href="https://wa.link/k333jq">WhatsApp</a></button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default AppHeader;